import selectAll from './select-all'
import xhr from 'xhr'

function getItemInfo (id, type, element) {
  let data = {
    id: id,
    type: type,
    modus: 'getModalBody'
  }
  xhr({
    body: JSON.stringify(data),
    uri: 'ajax.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, function (err, resp, body) {
    if (err) {
      return false
    }
    let item = JSON.parse(body)
    if (item.body) {
      document.getElementById('modal-body').innerHTML = item.body
      modal(element)
    }
  })
}

export function modal (elem) {
  let closeButton = selectAll('.close')
  elem.classList.add('open')
  document.body.classList.add('stop-scrolling')
  if (closeButton !== null) {
    for (var i = closeButton.length - 1; i >= 0; i--) {
      closeButton[ i ].removeEventListener('click', function () {})
      closeButton[ i ].addEventListener('click', function () {
        elem.classList.remove('open')
        document.body.classList.remove('stop-scrolling')
        if (this.classList.contains('reload')) {
          window.location.reload()
        }
      }, false)
    }
  }
}

export default function initModal () {
  let modals = selectAll('.modal-open')
  if (modals !== null) {
    for (var i = modals.length - 1; i >= 0; i--) {
      modals[ i ].removeEventListener('click', function () {})
      modals[ i ].addEventListener('click', function (event) {
        event.stopPropagation()
        event.preventDefault()
        let element = document.getElementById(this.getAttribute('data-target'))
        if (this.classList.contains('modal-body')) {
          getItemInfo(this.getAttribute('data-id'), this.getAttribute('data-type'), element)
        } else {
          modal(element)
        }
        return false
      }, { passive: false })
    }
  }
}
